import { render, staticRenderFns } from "./Layout4.vue?vue&type=template&id=532a1e8a&scoped=true"
import script from "./Layout4.vue?vue&type=script&lang=js"
export * from "./Layout4.vue?vue&type=script&lang=js"
import style0 from "./Layout4.vue?vue&type=style&index=0&id=532a1e8a&prod&lang=css"
import style1 from "./Layout4.vue?vue&type=style&index=1&id=532a1e8a&prod&scoped=true&lang=css"
import style2 from "./Layout4.vue?vue&type=style&index=2&id=532a1e8a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532a1e8a",
  null
  
)

export default component.exports